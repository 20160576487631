import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

type Identifiers = {
  companyId: string;
  userIdentifier: string;
};
export interface UserSlice {
  identifiers: Identifiers[];
  userIdentifier: string;
}

const initialState: UserSlice = {
  identifiers: [],
  userIdentifier: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: { payload: { companyId: string } }) => {
      const checkCompanyExists = state.identifiers.find(
        (item) => item.companyId === action.payload.companyId,
      );
      if (!checkCompanyExists) {
        const userIdentifier = uuidv4();
        state.identifiers.push({
          companyId: action.payload.companyId,
          userIdentifier,
        });
        state.userIdentifier = userIdentifier;
      } else {
        state.userIdentifier = checkCompanyExists.userIdentifier;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
