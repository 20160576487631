import { createSlice } from "@reduxjs/toolkit";
import { ChatItem, CompanyData } from "../../types";
import { DEFAULT_CONFIG } from "../../defaults";

export interface ChatSlice {
  companyData: CompanyData;
  messages: ChatItem[];
  isServerVerified: boolean;
  isMessageWaiting: boolean;
  isSocketConnected: boolean;
}

const initialState: ChatSlice = {
  companyData: {
    _id: "",
    name: "",
    photoKey: "",
    photoUrl: "",
    welcomeMessage: "",
    allowedOrigins: [""],
    chatConfig: DEFAULT_CONFIG,
  },
  messages: [],
  isServerVerified: false,
  isMessageWaiting: false,
  isSocketConnected: false,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setCompanyData: (state, action) => {
      state.companyData = action.payload;
      state.messages.push({
        id: new Date().getTime().toString(),
        date: new Date().getTime(),
        text: action.payload.welcomeMessage,
        sender: "assistant",
        status: "sent",
      });
      state.isServerVerified = true;
    },
    setUserMessages: (state, action) => {
      state.messages = action.payload;
    },
    addMessage: (state, action: { payload: ChatItem }) => {
      state.messages.push(action.payload);
    },
    setMessageWaiting: (state, action: { payload: boolean }) => {
      if (action.payload) {
        const tmpMessages = [...state.messages];
        tmpMessages
          .filter((item) => item.status === "sending")
          .map((item) => (item.status = "sent"));
        tmpMessages.push({
          id: `loading-${new Date().getTime().toString()}`,
          date: new Date().getTime(),
          text: "loading",
          sender: "assistant",
          status: "sent",
        });
        state.messages = tmpMessages;
      } else {
        const tmpMessages = [...state.messages];
        tmpMessages
          .filter((item) => item.status === "sending")
          .map((item) => (item.status = "sent"));
        state.messages = tmpMessages.filter(
          (message) => !message.id.includes("loading"),
        );
      }
      state.isMessageWaiting = action.payload;
    },
    setSocketConnection: (state, action: { payload: boolean }) => {
      state.isSocketConnected = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCompanyData,
  setUserMessages,
  addMessage,
  setMessageWaiting,
  setSocketConnection,
} = chatSlice.actions;

export default chatSlice.reducer;
