import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import ChatHeader from "./ChatHeader";
import ChatInput from "./ChatInput";
import ChatFooter from "./ChatFooter";
import ChatArea from "./ChatArea";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import {
  addMessage,
  setMessageWaiting,
  setSocketConnection,
} from "../store/chat/chatSlice";
import { isMobile, browserName } from "react-device-detect";
import messageReceivedSound from "../assets/sounds/messageReceived.mp3";
import { v4 as uuidv4 } from "uuid";

const Chat = () => {
  const dispatch = useDispatch();
  const {
    chat: { companyData, messages },
    user: { userIdentifier },
  } = useSelector((state: RootState) => state);
  const [socket, setSocket] = useState<WebSocket>();
  const messagesScrollRef = useRef<HTMLDivElement>(null);

  const connectSocket = () => {
    const websocket = new WebSocket(
      "wss://lr5hpvml0j.execute-api.us-east-1.amazonaws.com/production/",
    );
    websocket.onopen = () => {
      websocket.send(
        JSON.stringify({
          action: "webConnect",
          webIdentifier: userIdentifier,
          // @ts-ignore
          language: navigator.language || navigator.userLanguage,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          deviceType: isMobile ? "mobile" : "desktop",
          companyId: companyData._id,
          browserName,
        }),
      );
    };
    websocket.onmessage = (message) => {
      try {
        const data = JSON.parse(message.data);
        if (data.connected) {
          dispatch(setSocketConnection(true));
        }
        if (data.isAIWaiting) {
          dispatch(setMessageWaiting(true));
        }
        if (!data.isAIWaiting) {
          dispatch(setMessageWaiting(false));
        }
        if (data.message) {
          const messageReceived = new Audio(messageReceivedSound);
          messageReceived
            .play()
            .catch((err) => console.log("error when playing sound! -> ", err));
          dispatch(
            addMessage({
              id: uuidv4(),
              date: data.message.createdAt,
              text: data.message.content,
              sender: data.message.role,
              assistantName: data.message.assistantName,
              assistantPhotoKey: data.message.assistantPhotoKey,
              assistantPhotoUrl: data.message.assistantPhotoUrl,
              products: data.message.products || [],
              couponCode: data.message.couponCode,
              status: "sent",
            }),
          );
          dispatch(setMessageWaiting(false));
        }
      } catch (e) {
        console.log("socket data couldnt parsed");
      }
    };
    websocket.onclose = () => {
      console.log("socked closed");
      dispatch(setSocketConnection(false));
      setTimeout(() => {
        connectSocket();
      }, 1000);
    };
    setSocket(websocket);
  };

  useEffect(() => {
    if (!socket && companyData.name && userIdentifier) {
      connectSocket();
    }
    return () => {
      socket?.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData]);

  useMemo(() => {
    setTimeout(() => {
      messagesScrollRef.current?.scrollTo({
        left: 0,
        top: 9999999,
        behavior: "smooth",
      });
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  if (!companyData.name) {
    return null;
  }

  return (
    <>
      <ChatHeader
        photoUrl={companyData.photoUrl}
        companyName={companyData.name}
      />
      <ChatArea scrollRef={messagesScrollRef} messages={messages} />
      <ChatInput socket={socket} />
      <ChatFooter />
    </>
  );
};

export default memo(Chat);
