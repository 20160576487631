import React, { LegacyRef, useEffect, useRef, useState } from "react";
import ChatBubble from "./ChatBubble";
import { ChatItem } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { DEFAULT_CONFIG } from "../defaults";

type Props = {
  scrollRef: LegacyRef<HTMLDivElement>;
  messages: ChatItem[];
};

const ChatArea: React.FC<Props> = ({ scrollRef, messages }) => {
  const {
    companyData: { chatConfig, photoUrl },
  } = useSelector((state: RootState) => state.chat);
  const assistantImgRef = useRef<HTMLImageElement>(null);
  const [assistantImageUrl, setAssistantImageUri] = useState("");

  useEffect(() => {
    const assistantMessages = messages.filter(
      (item) => item.sender === "assistant",
    );
    const lastAssistantMessage =
      assistantMessages[assistantMessages.length - 1];
    setAssistantImageUri(lastAssistantMessage?.assistantPhotoUrl || photoUrl);
    const messageElement = document.getElementById(lastAssistantMessage.id);
    if (messageElement) {
      const willAnimateTo =
        messageElement.offsetTop + messageElement.offsetHeight - 24;
      if (assistantImgRef.current) {
        assistantImgRef.current.style.top = `${willAnimateTo}px`;
      }
    }
  }, [messages]);

  return (
    <div
      className="biens-chat-area"
      ref={scrollRef}
      style={{ background: chatConfig.cBg || DEFAULT_CONFIG.cBg }}
    >
      <img
        src={assistantImageUrl}
        alt="random-user"
        className="biens-agent-img"
        ref={assistantImgRef}
      />
      {messages.map((chatItem) => (
        <ChatBubble key={chatItem.id} chat={chatItem} />
      ))}
    </div>
  );
};

export default ChatArea;
