import { ChatItem } from "../types";
import React from "react";
import Linkify from "linkify-react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { DEFAULT_CONFIG } from "../defaults";
import Loading from "./Loading";

type Props = {
  chat: ChatItem;
};

const ChatBubble: React.FC<Props> = ({ chat }) => {
  const {
    companyData: { chatConfig },
  } = useSelector((state: RootState) => state.chat);

  if (chat.id.includes("loading")) {
    return <Loading chatId={chat.id} />;
  }

  console.log(chat, "<-- chat");
  return (
    <Linkify options={{ target: "_blank" }}>
      <div id={chat.id} className={`biens-chat-message ${chat.sender}`}>
        <div
          className={`biens-chat-bubble ${chat.sender} ${chat.status}`}
          style={{
            background:
              chat.sender === "assistant"
                ? chatConfig.rmBg || DEFAULT_CONFIG.rmBg
                : chatConfig.smBg || DEFAULT_CONFIG.smBg,
          }}
        >
          <h6
            style={{
              color:
                chat.sender === "assistant"
                  ? chatConfig.rmTc || DEFAULT_CONFIG.rmTc
                  : chatConfig.smTc || DEFAULT_CONFIG.smTc,
            }}
          >
            {chat.text}
          </h6>
          {chat.products?.length ? (
            <div className="chat-product-container">
              {chat.products.map((product) => (
                <div key={product.id} className="chat-product">
                  <img src={product.photoUrl} alt={product.name} />
                  <span
                    style={{
                      color:
                        chat.sender === "assistant"
                          ? chatConfig.rmTc || DEFAULT_CONFIG.rmTc
                          : chatConfig.smTc || DEFAULT_CONFIG.smTc,
                    }}
                  >
                    {product.name}
                  </span>
                  <button
                    style={{
                      background: chatConfig.smBg || DEFAULT_CONFIG.smBg,
                      color: chatConfig.smTc || DEFAULT_CONFIG.smTc,
                    }}
                    onClick={() => window.open(product.url, "_blank")}
                  >
                    Ürüne Git
                  </button>
                </div>
              ))}
            </div>
          ) : chat.couponCode ? (
            <div
              className="chat-coupon-code-container"
              style={{ borderColor: chatConfig.rmDc || DEFAULT_CONFIG.rmDc }}
            >
              <h6
                className="coupon-title"
                style={{ color: chatConfig.rmTc || DEFAULT_CONFIG.rmTc }}
              >
                {chat.couponCode.title}
              </h6>
              <span
                className="coupon-desc"
                style={{ color: chatConfig.rmDc || DEFAULT_CONFIG.rmDc }}
              >
                {chat.couponCode.desc}
              </span>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(chat.couponCode!.code);
                }}
                className="coupon-code"
                style={{
                  background: chatConfig.smBg || DEFAULT_CONFIG.smBg,
                  color: chatConfig.smTc || DEFAULT_CONFIG.smTc,
                }}
              >
                {chat.couponCode.code}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    d="M208 0L332.1 0c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9L448 336c0 26.5-21.5 48-48 48l-192 0c-26.5 0-48-21.5-48-48l0-288c0-26.5 21.5-48 48-48zM48 128l80 0 0 64-64 0 0 256 192 0 0-32 64 0 0 48c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 176c0-26.5 21.5-48 48-48z"
                    fill={chatConfig.smTc || DEFAULT_CONFIG.smTc}
                  />
                </svg>
              </button>
            </div>
          ) : null}
          <span
            style={{
              color:
                chat.sender === "assistant"
                  ? chatConfig.rmDc || DEFAULT_CONFIG.rmDc
                  : chatConfig.smDc || DEFAULT_CONFIG.smDc,
            }}
          >
            19:36
          </span>
          {chat.sender === "assistant" ? (
            <div
              className="biens-assistant-info"
              style={{
                color: chatConfig.rmDc || DEFAULT_CONFIG.rmDc,
                borderTop: `1px solid ${chatConfig.rmDc || DEFAULT_CONFIG.rmDc}50`,
              }}
            >
              Sent by {chat.assistantName || "biens"}
            </div>
          ) : null}
        </div>
      </div>
    </Linkify>
  );
};

export default ChatBubble;
