import React, { memo } from "react";
import DotIcon from "./Icons/DotIcon";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { DEFAULT_CONFIG } from "../defaults";

type Props = {
  chatId: string;
};
const Loading: React.FC<Props> = ({ chatId }) => {
  const {
    companyData: { chatConfig },
  } = useSelector((state: RootState) => state.chat);

  return (
    <div id={chatId} className={`biens-chat-message assistant`}>
      <div
        className={`biens-chat-bubble assistant biens-loading-container`}
        style={{
          background: chatConfig.rmBg || DEFAULT_CONFIG.rmBg,
        }}
      >
        <DotIcon
          height={10}
          color={chatConfig.aSIc || DEFAULT_CONFIG.aSIc}
          className="biens-loading-animation"
        />
        <DotIcon
          height={10}
          color={chatConfig.aSIc || DEFAULT_CONFIG.aSIc}
          className="biens-loading-animation-slow"
        />
        <DotIcon
          height={10}
          color={chatConfig.aSIc || DEFAULT_CONFIG.aSIc}
          className="biens-loading-animation-slowest"
        />
      </div>
    </div>
  );
};

export default memo(Loading);
