import { useEffect } from "react";
import axios from "axios";
import { setCompanyData } from "../store/chat/chatSlice";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { setUserData } from "../store/chat/userSlice";

const Layout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const originUrl = document.location.ancestorOrigins[0];
    axios
      .get("https://api.biens.ai/chat-info", {
        params: {
          origin: originUrl,
        },
      })
      .then(({ data }) => {
        if (data?.data && data?.data?.allowedOrigins?.length) {
          if (process.env.NODE_ENV !== "development" && originUrl) {
            const allowedDomains = data.data.allowedOrigins;
            const checkIsDomainVerified = allowedDomains.includes(originUrl);
            if (checkIsDomainVerified) {
              dispatch(setCompanyData(data.data));
              dispatch(setUserData(data.data._id));
            }
          } else {
            dispatch(setCompanyData(data.data));
            dispatch(setUserData(data.data._id));
          }
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Outlet />;
};

export default Layout;
